<template>
    <div>
        <div>
            <label class="font-weight-semibold">
                {{ $trans('Answer') }}:
            </label>
            <i>{{ replies }}</i>
        </div>
        <div v-if="!isPassed">
            <label class="font-weight-semibold">
                {{ $trans('Correct') }}:
            </label>
            <i>{{ correct }}</i>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import questionsTypeBasePropsMixin from './mixins/questionsTypeBasePropsMixin'

export default {
    name: 'AnswersVariantResult',
    mixins: [ questionsTypeBasePropsMixin ],
    computed: {
        isPassed() {
            return _.isEqual(this.value.correct, this.reply)
        },
        replies() {
            const variants = this.value.variants
            const items = []

            for (let i = 0; i < this.reply.length; i++) {
                items.push(variants.find(o => o.id === this.reply[i]).value)
            }

            return items.join(', ')
        },
        correct() {
            const correct = this.value.correct
            const variants = this.value.variants
            const items = []

            for (let i = 0; i < correct.length; i++) {
                items.push(variants.find(o => o.id === correct[i]).value)
            }

            return items.join(', ')
        }
    }
}
</script>

<style lang=scss>

</style>
